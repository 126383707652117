import { TenantGuard } from './guards/tenant.guard';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { TranslateModule } from '@ngx-translate/core';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { AgmCoreModule } from '@agm/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
// import { ScrollContainerComponent } from 'app/components/scroll-container/scroll-container.component';

import { AuthGuard } from 'app/guards/auth.guard';
// import { RoleGuardService as RoleGuard } from './guards/role.guard';
import { environment } from 'environments/environment';

import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { PipesModule } from './pipes/pipes.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializerKeycloak } from 'KeycloakInit';
import { AuthService } from './services/auth.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ClipboardModule } from 'ngx-clipboard';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { RecaptchaModule } from "ng-recaptcha";

import { registerLocaleData } from '@angular/common';
// importar locales
import {MAT_DATE_FORMATS} from '@angular/material/core';
import localePy from '@angular/common/locales/es-PY';

export const MY_FORMATS = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      
      dateA11yLabel: 'DD/MM/YYYY',
      monthYearA11yLabel: 'MMMM YYYY',
    },
};

registerLocaleData(localePy, 'es-PY');

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled', 
    scrollOffset: [0, 64],
    onSameUrlNavigation: "reload"
};

const config: SocketIoConfig = { 
    //url: environment.url.replace("api/", ""), 
    //url: 'http://localhost:3002',
    url: environment.urlWSS,
    options: { transports: ['websocket', 'polling'] }
};

const appRoutes: Routes = [
    {
       path: '', redirectTo: '/home', pathMatch: 'full'    //La pagina que esta aqui en '' siempre abre primero ejecuta su ngoninity todo
    }, { 
        path: 'empresa-select', loadChildren: () =>
        import('./pages/empresas/empresa-list.module').then(m => m.EmpresaListModule),
        canActivate: [AuthGuard],
        data : {
            roles : []
        }
    }, {
        path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
        canActivate: [TenantGuard],
        data : {
            roles : []
        }
    }, {
        path: 'dashboard', loadChildren: () => 
        import('./dashboards/dashbords.module').then(m => m.DashboardModule),
        canActivate: [TenantGuard],
        data : {
            roles : []
        }
    }, {
        path: 'search-result/:query', loadChildren: () => 
        import('./pages/search/search-result/search-result.module').then(m => m.SearchResultModule),
        canActivate: [TenantGuard],
        data : {
            roles : []
        }
    }, {
        path: 'de/:tipoDocumento', loadChildren: () => 
        import('./pages/de/de-list/de-list.module').then(m => m.DeListModule), 
        canActivate: [TenantGuard]
    }, {
        path: 'de/:tipoDocumento/:filter', loadChildren: () => 
        import('./pages/de/de-list/de-list.module').then(m => m.DeListModule), 
        canActivate: [TenantGuard]
    }, {
        path: 'de-new', loadChildren: () => 
        import('./pages/de/de-new/de-new.module').then(m => m.DeNewModule), 
        canActivate: [TenantGuard]
    }, {
        path: 'de-view', loadChildren: () => 
        import('./pages/de/de-edit/de-edit.module').then(m => m.DeEditModule), 
        canActivate: [TenantGuard]
    }, {
        path: 'recibo', loadChildren: () => 
        import('./pages/recibo/recibo-list/recibo-list.module').then(m => m.ReciboListModule), 
        canActivate: [TenantGuard]
    }, {
        path: 'recibo/:filter', loadChildren: () => 
        import('./pages/recibo/recibo-list/recibo-list.module').then(m => m.ReciboListModule), 
        canActivate: [TenantGuard]
    }, {
        path: 'recibo-new', loadChildren: () => 
        import('./pages/recibo/recibo-new/recibo-new.module').then(m => m.ReciboNewModule), 
        canActivate: [TenantGuard]
    }, {
        path: 'recibo-view', loadChildren: () => 
        import('./pages/recibo/recibo-edit/recibo-edit.module').then(m => m.ReciboEditModule), 
        canActivate: [TenantGuard]
    }, {
        path: 'lotes', loadChildren: () => 
        import('./pages/lote/lote-list/lote-list.module').then(m => m.LoteListModule), 
        canActivate: [TenantGuard]
    }, {
        path: 'eventos', loadChildren: () => 
        import('./pages/evento/evento-list/evento-list.module').then(m => m.EventoListModule), 
        canActivate: [TenantGuard]
    }, {
        path: 'resumen-test', loadChildren: () => 
        import('./pages/resumen-test/resumen-test.module').then(m => m.ResumenTestModule), 
        canActivate: [TenantGuard]
    }, {
        path: 'usuarios', loadChildren: () => 
        import('./pages/usuario/usuario-list/usuario-list.module').then(m => m.UsuarioListModule), 
        canActivate: [TenantGuard]
    }, {
        path: 'usuario-edit', loadChildren: () => 
        import('./pages/usuario/usuario-edit/usuario-edit.module').then(m => m.UsuarioEditModule), 
        canActivate: [TenantGuard]
    }, {
        path: 'usuario-invitar', loadChildren: () => 
        import('./pages/usuario/usuario-invitar/usuario-invitar.module').then(m => m.UsuarioInvitarModule), 
        canActivate: [TenantGuard]
    }, {
        path: 'contribuyente-emisor', loadChildren: () => 
        import('./pages/informacion-fiscal/informacion-fiscal.module').then(m => m.InformacionFiscalModule), 
        canActivate: [TenantGuard]
    }, {
        path: 'pages', loadChildren: () => 
        import('./pages/pages.module').then(m => m.PagesModule),
        canActivate: [TenantGuard]
    }, {
        path: 'consulta-ruc', loadChildren: () => 
        import('./pages/consultas/consulta-ruc/consulta-ruc.module').then(m => m.ConsultaRucModule), 
        canActivate: [TenantGuard]
    }, {
        path: 'consulta-cdc', loadChildren: () => 
        import('./pages/consultas/consulta-cdc/consulta-cdc.module').then(m => m.ConsultaCdcModule), 
        canActivate: [TenantGuard]
    }, { 
        path: 'export-csv', loadChildren: () =>
        import('./pages/export-csv/export-csv.module').then(m => m.ExportCSVModule),
        canActivate: [AuthGuard],
        data : {
            roles : []
        }
    }, {
        path: 'settings', loadChildren: () => 
        import('./pages/settings/settings.module').then(m => m.SettingsModule), 
        canActivate: [TenantGuard]
    }, {
        path: 'registrar-instalacion', loadChildren: () => 
        import('./pages/registrar-instalacion/registrar-instalacion.module').then(m => m.RegistrarInstalacionModule), 
        canActivate: [TenantGuard]
    }, 
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, routerOptions),
        SocketIoModule.forRoot(config),
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,
        
        // Material
        MatMenuModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatGridListModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        PipesModule,
        MatProgressSpinnerModule, 
        //MatCheckbox,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        // App modules
        LayoutModule,
        
        NgxJsonViewerModule,
        //AngularFireModule.initializeApp(environment.firebaseConfig),
        
        AngularFirestoreModule,
        KeycloakAngularModule,
        ClipboardModule,
        RecaptchaModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAK8VtkdVtK0Z5whewu1gOV6pT7CjETVRc'
        }),
    ],
    providers: [
        //[{provide: APP_BASE_HREF, useValue: '/'}]
        //KeycloakService, 
        {
            provide : APP_INITIALIZER,
            useFactory : initializerKeycloak,
            multi : true, 
            deps : [KeycloakService]
        }, AuthService,
        { provide: LOCALE_ID, useValue: 'es-PY' },
         {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ],
    bootstrap: [AppComponent]
})
export class AppModule
{
}
